<template>
  <div id="user-list">
    <client-list-add-new
      v-model="isAddNewUserSidebarActive"
      @refetch-data="
        fetchClients()
        fetchClientsOverview()
      "
    ></client-list-add-new>
    <client-list-export v-model="isExportSidebarActive"></client-list-export>

    <v-row class="mb-5">
      <v-col v-for="total in overview" :key="total.title" cols="12" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.count }}
              </h2>
              <span>{{ $t(`${total.title}`) }}</span>
            </div>

            <v-avatar
              :color="resolveClientTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveClientTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveClientTotalIcon(total.title).color" class="rounded-0">
                {{ resolveClientTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="statusesFilter"
            :placeholder="$t('selectStatus')"
            :items="choices.statusChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            v-model="tagsFilter"
            :placeholder="$t('selectTag')"
            :items="choices.tagsChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            v-model="cityFilter"
            :placeholder="$t('selectCity')"
            :items="choices.cityChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4"> </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isExportSidebarActive = !isExportSidebarActive">
            <v-icon class="me-2">{{ icons.mdiExportVariant }}</v-icon>
            <span v-t="'export'" />
          </v-btn>
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive">
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span v-t="'addNewClient'" />
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="clients"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalClientListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
          'items-per-page-options': [10, 50, 250],
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title v-t="'deleteClient'" class="justify-center text-h5" />
              <v-card-text>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="d-flex align-center justify-center flex-column">
                      <div>{{ $t('confirmationModalUndoneQuestion') }}</div>
                      <div>{{ $t('deleteClientProjectInfo') }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-center mt-3">
                      <v-btn v-t="'discard'" outlined class="me-3" @click="discardDelete" />
                      <v-btn v-t="'delete'" color="error" @click="confirmDelete" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`header.name`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.status`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`header.tags`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.projectsAmount`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.positionsAmount`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.projectsWorkload`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.city`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-client-view', params: { id: item.id } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
              <span class="text-xs">{{ item.registrationName }}</span>
            </div>
          </div>
        </template>

        <template #[`item.tags`]="{ item }">
          <span v-if="!(item.tags && item.tags.length)">-/-</span>
          <v-chip
            v-for="(tag, index) in item.tags"
            v-else
            :key="index"
            small
            label
            color="info"
            outlined
            class="font-weight-semibold ma-1"
            v-text="tag.tag"
          />
        </template>

        <template #[`item.projectsWorkload`]="{ item }">
          <span>{{ `${item.projectsWorkload}%` }}</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip bottom>
              <template #activator="tooltipActivator">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="tooltipActivator.attrs"
                  v-on="tooltipActivator.on"
                  @click="deleteItem(item.id)"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'delete'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" :to="{ name: 'apps-client-view', params: { id: item.id } }" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'view'" />
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiDeleteOutline, mdiEyeOutline, mdiExportVariant } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

import { avatarText } from '@core/utils/filter'
import { useActions } from 'vuex-composition-helpers'
import ClientListAddNew from './ClientListAddNew.vue'
import useClientList from './useClientList'
import ClientListExport from './ClientListExport.vue'

export default {
  components: {
    ClientListAddNew,
    ClientListExport,
  },
  setup() {
    const isDialogVisible = ref(false)
    const isAddNewUserSidebarActive = ref(false)
    const isExportSidebarActive = ref(false)

    const { deleteClient } = useActions('staff', ['deleteClient'])

    const {
      fetchClientsOverview,
      fetchClients,
      resolveClientTotalIcon,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalClientListTable,
      loading,
      options,
      userTotalLocal,
      overview,
      clients,
      choices,
      statusesFilter,
      cityFilter,
      tagsFilter,
    } = useClientList()

    onMounted(async () => {
      await fetchClientsOverview()
    })

    const itemToDeleteId = ref(null)
    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value !== null) {
        await deleteClient(itemToDeleteId.value)
        await fetchClients()
        await fetchClientsOverview()
      }
      isDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDialogVisible.value = false
    }

    return {
      avatarText,
      fetchClientsOverview,
      fetchClients,
      resolveClientTotalIcon,
      deleteItem,
      confirmDelete,
      discardDelete,
      delaySearchHandler,

      isDialogVisible,
      tableColumns,
      searchQuery,
      totalClientListTable,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      isExportSidebarActive,
      overview,
      clients,
      choices,
      statusesFilter,
      cityFilter,
      tagsFilter,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiExportVariant,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
