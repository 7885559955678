<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'addNewClient'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-text-field
          v-model="clientData.name"
          outlined
          dense
          :rules="[validators.required]"
          :label="$t('name')"
          :placeholder="$t('name')"
          hide-details="auto"
          maxlength="150"
          class="mb-3"
        ></v-text-field>
        <v-expansion-panels class="mb-3" multiple>
          <v-expansion-panel v-for="(expansionPanel, index) in expansionPanels" :key="index" class="test">
            <v-expansion-panel-header> {{ $t(expansionPanel.title) }} </v-expansion-panel-header>
            <v-expansion-panel-content v-if="index === 0">
              <v-text-field
                v-model="clientData.registrationName"
                outlined
                dense
                :label="$t('registrationName')"
                :placeholder="$t('registrationName')"
                hide-details="auto"
                maxlength="150"
                class="mb-3"
              ></v-text-field>

              <v-textarea
                v-model="clientData.description"
                outlined
                auto-grow
                :label="$t('description')"
                :placeholder="$t('description')"
                rows="4"
                maxlength="1000"
                hide-details="auto"
                class="mb-3"
              />
              <v-autocomplete
                v-model="clientData.status"
                :label="$t('status', { postfix: '' })"
                :placeholder="$t('status', { postfix: '' })"
                :items="statusChoices"
                outlined
                dense
                clearable
                hide-details
                class="mb-3"
              ></v-autocomplete>

              <v-combobox
                v-model="clientData.tags"
                :items="tagsChoices"
                class="mb-3"
                :label="$t('tag')"
                :placeholder="$t('tag')"
                item-text="tag"
                item-value="id"
                multiple
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    small
                    label
                    color="info"
                    outlined
                    class="font-weight-semibold disable-min-height--chip mt-1"
                  >
                    <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
                    <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox>

              <v-text-field
                v-model="clientData.contactEmail"
                outlined
                dense
                :label="$t('contactEmail')"
                :placeholder="$t('contactEmail')"
                hide-details="auto"
                maxlength="150"
                class="mb-3"
                :rules="[validators.emailValidator]"
              ></v-text-field>

              <v-text-field
                v-model="clientData.taxId"
                outlined
                dense
                :label="$t('taxId')"
                :placeholder="$t('taxId')"
                hide-details="auto"
                maxlength="150"
                class="mb-3"
              ></v-text-field>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="index === 1">
              <v-text-field
                v-for="customField in customFields"
                :key="customField.id"
                v-model="customField.field"
                outlined
                dense
                :label="customField.name"
                :placeholder="customField.name"
                hide-details
                maxlength="100"
                class="mb-3"
              ></v-text-field>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="index === 2">
              <v-row>
                <v-col v-for="(file, fileIndex) in clientData.files" :key="`Client--File-${fileIndex}`" cols="12">
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <file-pond
                            v-model="file.file"
                            :label-idle="$t('filePondIdle')"
                            :allow-multiple="false"
                            accepted-file-types="application/pdf, image/png, image/jpeg"
                            max-file-size="20MB"
                            max-total-file-size="20MB"
                            @addfile="setUploadedFileName(file)"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="file.name"
                            outlined
                            dense
                            :rules="[validators.required]"
                            :label="$t('name')"
                            :placeholder="$t('name')"
                            maxlength="150"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-combobox
                            v-model="file.types"
                            :items="fileTypes"
                            :label="$t('type')"
                            :placeholder="$t('type')"
                            item-text="type"
                            item-value="id"
                            multiple
                            outlined
                            dense
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="10">
                          <v-menu
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="file.validUntil"
                                :label="$t('validUntil', { date: '' })"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                hide-details="auto"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="file.validUntil"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-end">
                          <v-tooltip top transition="scroll-y-transition" open-delay="150" color="secondary">
                            <template #activator="{ on, attrs }">
                              <v-btn
                                elevation="0"
                                color="error"
                                icon
                                v-bind="attrs"
                                @click="deleteFileToUpload(clientData.files, fileIndex)"
                                v-on="on"
                              >
                                <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-xs"> {{ $t('removeFile') }} </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-btn color="primary" block @click="addFileToUploadHandler">{{ $t('addFile') }}</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="index === 3">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="clientData.street"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('street')"
                    :placeholder="$t('street')"
                    maxlength="100"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                  <v-text-field
                    v-model="clientData.houseNumber"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('houseNumber')"
                    :placeholder="$t('houseNumber')"
                    maxlength="20"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                  <v-text-field
                    v-model="clientData.flatNumber"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('flatNumber')"
                    :placeholder="$t('flatNumber')"
                    maxlength="20"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="8" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                  <v-text-field
                    v-model="clientData.city"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('city')"
                    :placeholder="$t('city')"
                    maxlength="50"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                  <v-text-field
                    v-model="clientData.zipCode"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('zipCode')"
                    :placeholder="$t('zipCode')"
                    maxlength="10"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                  <v-text-field
                    v-model="clientData.state"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('state')"
                    :placeholder="$t('state')"
                    maxlength="50"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.mdAndUp ? 'pl-3' : '']">
                  <v-text-field
                    v-model="clientData.country"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('country')"
                    :placeholder="$t('country')"
                    maxlength="50"
                    class="mb-3"
                  ></v-text-field> </v-col
              ></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn v-t="'add'" color="primary" class="me-3" type="submit" :disabled="!valid" block :loading="loading" />
          </v-col>
          <v-col cols="12" md="6">
            <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="resetClientData" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { required, emailValidator } from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import fileUploadUtils from '@/utils/fileUploadUtils'
import clientUtils from '@/utils/clientUtils'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileEncode, FilePondPluginFileValidateSize)

export default {
  components: {
    FilePond,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { fetchStatusChoices, statusChoices, fetchFileTypes, fileTypes, fetchTagChoices, tagsChoices } = clientUtils()
    const { setUploadedFileName, parseFilesToCreate, addFileToUpload, deleteFileToUpload } = fileUploadUtils()

    const expansionPanels = [
      {
        title: 'extraData',
      },
      {
        title: 'customFields',
      },
      {
        title: 'files',
      },
      {
        title: 'address',
      },
    ]

    const customFields = ref([])

    const blankClientData = {
      name: '',
      description: '',
      status: null,
      registrationName: '',
      contactEmail: '',
      taxId: '',
      street: '',
      houseNumber: '',
      flatNumber: '',
      city: '',
      zipCode: '',
      state: '',
      country: '',
      files: [],
      tags: [],
    }

    const { createClient, getClientCustomFields } = useActions('staff', ['createClient', 'getClientCustomFields'])

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const clientData = ref(_.cloneDeep(blankClientData))

    const resetClientData = () => {
      clientData.value = _.cloneDeep(blankClientData)
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true
        clientData.value.files = parseFilesToCreate(clientData.value.files)
        await createClient({
          ...clientData.value,
          tags: clientData.value.tags.map((tag) => {
            return _.isObject(tag) ? tag : { tag }
          }),
          customFieldsValues: customFields.value.map((field) => {
            return { customField: field.id, field: field.field }
          }),
        })
        loading.value = false
        resetClientData()
        emit('refetch-data')
      } else {
        validate()
      }
    }

    const addFileToUploadHandler = () => {
      addFileToUpload(clientData.value.files, { validUntil: true, types: true })
    }

    watch(
      () => props.isAddNewUserSidebarActive,
      async (newVal) => {
        if (newVal) {
          await fetchStatusChoices()
          await fetchFileTypes()
          await fetchTagChoices()
          customFields.value = await getClientCustomFields()
        }
      },
    )

    return {
      resetClientData,
      onSubmit,

      expansionPanels,
      form,
      loading,
      clientData,
      valid,
      statusChoices,

      setUploadedFileName,
      addFileToUploadHandler,
      deleteFileToUpload,
      fileTypes,
      tagsChoices,
      customFields,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
